import { Link } from "react-router-dom";
import { useGlobal } from "./GlobalContext";
import Header from "./Header";

const Home = () => {
  const global = useGlobal();
  return (
    <>
      <Header />
      <section className="content">
        <h1>Home Page</h1>
      </section>
    </>
  );
};

export default Home;
