import { useGlobal } from "./GlobalContext";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const global = useGlobal();
  const location = useLocation();

  if (!global.access_token) {
    return <Navigate to="/login" state={{ path: location.pathname }} />;
  }
  return children;
};
