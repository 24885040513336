import React, { useState } from "react";
import Register from "./components/Register";
import Login from "./components/Login";
import Admin from "./components/Admin";
import Home from "./components/Home";
import Profile from "./components/Profile";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { Link } from "react-router-dom";
import { GlobalProvider } from "./components/GlobalContext";

import { RequireAuth } from "./components/RequireAuth";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <GlobalProvider>
      <main className="App">
        <Routes>
        <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/admin"
            element={
              <RequireAuth>
                <Admin />
              </RequireAuth>
            }
          />
        </Routes>{" "}
      </main>
    </GlobalProvider>
  );
}

export default App;
