import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobal, useGlobalUpdate } from "./GlobalContext";
import fullLogo from "./assets/dpd3_logo_full.png";
import {
  faUser,
  faSignOut,
  faBars,
  faBookmark,
  faHome,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const global = useGlobal();
  const updateGlobal = useGlobalUpdate();
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const navigate = useNavigate();

  const processLogout = () => {
    updateGlobal("access_token", null);
  };

  const closeMenus = () => {
    updateGlobal("showLeftMenu", false);
    updateGlobal("showRightMenu", false);
  }

  useEffect(() => {
    closeMenus();
    window.addEventListener("click", closeMenus);
  }, []);

  return (
    <>
      <section className="headerArea">
        <div className="headerMenuLink">
          <FontAwesomeIcon
            icon={faBars}
            className="dropDownIcon"
            onClick={(e) => {
              e.stopPropagation();
              updateGlobal('showLeftMenu', !global.showLeftMenu)
            }}
          />
          <div
            className={`dropDown leftDropDown ${
              global.showLeftMenu && "leftDropDownToggle"
            }`}
          >
            <ul>
              <li
                className="dropDownItem"
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faHome} className="dropDownIcon" />
                &nbsp;Home
              </li>
              <li
                className="dropDownItem"
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faBookmark} className="dropDownIcon" />
                &nbsp;Page Two
              </li>
              <li
                className="dropDownItem"
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faBookmark} className="dropDownIcon" />
                &nbsp;Page Three
              </li>
            </ul>
          </div>
        </div>
        <div>
          <img className="headerLogo" src={fullLogo} alt="" />
        </div>
        <div
          className="headerUserProfileLink"
          onClick={(e) => {
            e.stopPropagation();
            updateGlobal('showRightMenu', !global.showRightMenu)
          }}
        >
          {global.user?.userName}&nbsp;&nbsp;
          <FontAwesomeIcon icon={faUser} />
          <div
            className={`dropDown rightDropDown ${
              global.showRightMenu && "rightDropDownToggle"
            }`}
          >
            <ul>
              <li
                className="dropDownItem"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <FontAwesomeIcon icon={faUser} className="dropDownIcon" />
                &nbsp;Profile
              </li>
              <li className="dropDownItem" onClick={processLogout}>
                <FontAwesomeIcon icon={faSignOut} className="dropDownIcon" />
                &nbsp;Sign Out
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
