import { useRef, useState, useEffect, useContext } from "react";
import smallLogo from "./assets/dpd3_small_logo.png";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";

// import {
//   faCheck,
//   faTimes,
//   faInfoCircle,
// } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobal, useGlobalUpdate } from "./GlobalContext";

function Login() {
  const global = useGlobal();
  const globalUpdate = useGlobalUpdate();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [invalidCreds, setInvalidCreds] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || "/";

  const handleRememberMeChange = (event) => {
    const newValue = event.target.checked;
    setRememberMe(newValue);
    localStorage.setItem("rememberMe", JSON.stringify(newValue));
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (error) {
      return null;
    }
  };

  const processSignIn = async (e) => {
    e.preventDefault();
    setInvalidCreds(false);

    var data = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post("users/login", data);
      console.table(response.data);
      globalUpdate("access_token", response.data.token);
      globalUpdate("refresh_token", response.data.refresh_token);
      globalUpdate("user", parseJwt(response.data.token));
      navigate(redirectPath, { replace: true });
    } catch (error) {
      // console.log(error);
      setInvalidCreds(true);
    }

    // save email to local storage if remember me selected...
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
    } else {
      localStorage.removeItem("rememberedEmail");
    }
  };

  useEffect(() => {
    const storedCheckboxState = localStorage.getItem("rememberMe");
    if (storedCheckboxState !== null) {
      setRememberMe(JSON.parse(storedCheckboxState));
    }
  }, []);

  useEffect(() => {
    // Retrieve the email from localStorage on component mount
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
    }
  }, []);

  return (
    <div className="appArea">
      <div>
        <img
          className="smallLogo"
          src={smallLogo}
          title="dpd3.com - Dermpath Disease Differential Diagnosis"
        />
      </div>

      <div className="appCard">
        <div className="appCardHeading">Sign in</div>

        {invalidCreds && (
          <>
            <div className="appCardFieldLine">
              <div className="fieldInError topError">
                Invalid credentials entered.
              </div>
            </div>
          </>
        )}

        <form
          onSubmit={(e) => {
            processSignIn(e);
          }}
        >
          <div className="appCardFieldLine">
            <div className="heading">Email address:</div>
            <input
              className="appCardInput"
              value={email}
              type="text"
              placeholder="Email"
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="appCardFieldLine">
            <div className="heading">Password:</div>
            <input
              className="appCardInput"
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>

          <div className="appCardFieldLine">
            <label className="checkboxLabel" htmlFor="rememberMe">
              <input
                value={rememberMe}
                className="appCardCheckbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => {
                  handleRememberMeChange(e);
                }}
                type="checkbox"
              />
              &nbsp;Remember me
            </label>
          </div>

          <div className="appCardFieldLine">
            <Button className="wideButton" variant="primary" type="submit">
              Sign In
            </Button>
          </div>
        </form>

        <div className="appCardFieldLine">
          <p className="tinyText centered">
            By clicking Sign In, you agrree to our <a href="">Terms</a> and have
            read and acknowledge our <a href="">Privacy Statement</a>
          </p>
        </div>

        <div className="appCardFieldLine">
          <p>
            Don't have an account?{" "}
            <Link to="/register">Create an account.</Link>
          </p>
        </div>
      </div>
      <div>&nbsp;</div>
      <p className="tinyText centered">
        &copy; {currentYear} DPD3.com. All rights reserved.
      </p>
      <p className="tinyText centered">
        Terms and conditions, features, support, pricing, and service options
        subject to change without notice.
      </p>
    </div>
  );
}

export default Login;
