import React, { useContext, createContext, useState } from "react";
import { Navigate } from "react-router-dom";

const GlobalContext = createContext(null);
const GlobalUpdateContext = createContext(null);

export function useGlobal() {
  return useContext(GlobalContext);
}

export function useGlobalUpdate() {
  return useContext(GlobalUpdateContext);
}


export function GlobalProvider({ children }) {
  const [global, setGlobal] = useState({
    access_token: null,
    refresh_token: null,
  });

  const updateGlobal = (varName, value) => {
    setGlobal((prevGlobal) => ({
      ...prevGlobal,
      [varName]: value,
    }));
  };

  return (
    <GlobalContext.Provider value={global}>
      <GlobalUpdateContext.Provider value={updateGlobal}>
        {children}
      </GlobalUpdateContext.Provider>
    </GlobalContext.Provider>
  );
}
