import { useRef, useState, useEffect } from "react";
import smallLogo from "./assets/dpd3_small_logo.png";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import { useGlobal } from "./GlobalContext";

import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Register() {
  const global = useGlobal();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const navigate = useNavigate();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  function hasUpperAndLowerCase(str) {
    const hasUpperCase = /[A-Z]/.test(str);
    const hasLowerCase = /[a-z]/.test(str);
    return hasUpperCase && hasLowerCase;
  }

  function hasANumber(str) {
    const hasANumber = /[0-9]/.test(str);
    return hasANumber;
  }

  function hasASymbol(str) {
    const symbolRegex = /[!@#$%^&*,.?:|~]/;
    return symbolRegex.test(str);
  }

  function goodPassword() {
    var good = true;
    if (!hasUpperAndLowerCase(password)) good = false;
    if (!hasANumber(password)) good = false;
    if (!hasASymbol(password)) good = false;
    if (!password.length > 7) good = false;
    return good;
  }

  function isValidEmail(email) {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const createAccount = async (event) => {
    event.preventDefault(event);
    setDuplicateEmail(false);

    var data = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post("users/register", data);
      if (response.data.message === "ERROR: Duplicate email.") {
        setDuplicateEmail(true);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log("==============");
      console.error("Error submitting form:");
      // Handle the error or display an error message
    }
  };

  return (
    <div className="appArea">
      <div>
        <img
          className="smallLogo"
          src={smallLogo}
          title="dpd3.com - Dermpath Disease Differential Diagnosis"
        />
      </div>

      <div className="appCard">
        <div className="appCardHeading">Create an Account</div>

        {duplicateEmail && (
          <>
            <div className="appCardFieldLine">
              <div className="fieldInError topError">
                This email address is already registered.{" "}
                <Link to="/login">Sign In</Link>
              </div>
            </div>
          </>
        )}

        <form
          onSubmit={(e) => {
            createAccount(e);
          }}
        >
          <div className="appCardFieldLine">
            <div className="heading">Email address:</div>
            <input
              autoFocus
              className={`appCardInput ${
                email && !isValidEmail(email) && "fieldInError"
              }`}
              value={email}
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {email && !isValidEmail(email) && (
              <div className="redFont tinyText">Not a valid email address.</div>
            )}
          </div>

          <div className="appCardFieldLine">
            <div className="heading">
              Confirm email:&nbsp;
              {email && email === confirmEmail && (
                <span className="greenFont">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
              {email && email !== confirmEmail && (
                <span className="redFont">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
            <input
              className={`appCardInput ${
                email && email !== confirmEmail && "fieldInError"
              }`}
              value={confirmEmail}
              type="text"
              placeholder="Confirm email"
              onChange={(e) => {
                setConfirmEmail(e.target.value);
              }}
            />
            {email && email !== confirmEmail && (
              <div className="redFont tinyText">Must match email address.</div>
            )}
          </div>

          <div className="appCardFieldLine">
            <div className="showPasswordLinkAnchor">
              <a
                className="showPasswordLink"
                href="#"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? "hide" : "show"}
              </a>
            </div>
            <div className="heading">
              Password:&nbsp;
              {password && goodPassword() && (
                <span className="greenFont">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
              {password && !goodPassword() && (
                <span className="redFont">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
            <input
              value={password}
              className={`appCardInput ${
                password && !goodPassword() && "fieldInError"
              }`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {password.length > 0 && (
              <ul className="tinyText passwordRequirementsList">
                <li
                  className={`${password.length > 7 ? "greenFont" : "redFont"}`}
                >
                  {password.length > 7 ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                  &nbsp; Use 8 or more characters
                </li>
                <li
                  className={`${
                    hasUpperAndLowerCase(password) ? "greenFont" : "redFont"
                  }`}
                >
                  {hasUpperAndLowerCase(password) ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                  &nbsp; Use upper and lower case letters
                </li>
                <li
                  className={`${
                    hasANumber(password) ? "greenFont" : "redFont"
                  }`}
                >
                  {hasANumber(password) ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                  &nbsp; Use a number
                </li>
                <li
                  className={`${
                    hasASymbol(password) ? "greenFont" : "redFont"
                  }`}
                >
                  {hasASymbol(password) ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                  &nbsp; Use a symbol
                </li>
              </ul>
            )}
          </div>

          <div className="appCardFieldLine">
            <div className="heading">
              Confirm password: &nbsp;
              {password && password === confirmPassword && (
                <span className="greenFont">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              )}
              {password && password !== confirmPassword && (
                <span className="redFont">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
            <input
              className={`appCardInput ${
                password && password !== confirmPassword && "fieldInError"
              }`}
              value={confirmPassword}
              type={showPassword ? "text" : "password"}
              placeholder="Confirm password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {password && password !== confirmPassword && (
              <div className="redFont tinyText">Must match password.</div>
            )}
          </div>

          <div className="appCardFieldLine">
            <Button
              className="wideButton"
              variant="primary"
              disabled={
                email &&
                password &&
                email === confirmEmail &&
                goodPassword() &&
                password === confirmPassword
                  ? false
                  : true
              }
              type="submit"
            >
              Create Account
            </Button>
          </div>
        </form>

        <div className="appCardFieldLine">
          <p className="tinyText centered">
            By clicking Create Account, you agrree to our <a href="#">Terms</a>{" "}
            and have read and acknowledge our <a href="#">Privacy Statement</a>
          </p>
        </div>

        <div className="appCardFieldLine">
          <p className="centered">
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
      <div>&nbsp;</div>
      <p className="tinyText centered">
        &copy; {currentYear} DPD3.com. All rights reserved.
      </p>
      <p className="tinyText centered">
        Terms and conditions, features, support, pricing, and service options
        subject to change without notice.
      </p>
    </div>
  );
}

export default Register;
