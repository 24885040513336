import axios from "axios";

function getLocation() {
  var location = "https://app.dpd3.com/api/";
  if (window.location.href.includes("dev.")) {
    location = "https://dev.dpd3.com/api/";
  }
  if (window.location.href.includes("localhost")) {
    location = "http://localhost:8888/api/";
  }
  return location;
}

export default axios.create({
  baseURL: getLocation(),
});
